.section {
  margin-top: 20px;
  width: 100%;

  &__title {
    margin: 0;
  }
  &__hr {
    margin: 10px 0;
  }
}