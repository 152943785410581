.rules {

  h2 {
    margin-top: 2em;
  }
  ol, ul {
    padding-inline-start: 1em;
    line-height: 1.2em;
  }
  li {
    margin-top: 1em;
  }
}