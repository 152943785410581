@import '../graident.scss';

body {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  @media (min-width: 800px) {
    font-size: 1.2em;
  }
}

a {
  text-decoration: underline;
  color: white;
  font-weight: 500;
  &:visited {
    color: white;
  }
}

h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 1em;
}

h1 {
  margin-bottom: 0;
}
p {
  margin-top: 1em;
  line-height: 1.2em;
}

.App {
  @extend %gradient;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  &__header {
    width: 100%;
    height: 44px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: red;
    img {
      height: 32px;
    }
  }

  &__body {
    padding: 0 20px;
    // max-width: 800px;
    @media (min-width: 580px) {
      padding: 0 100px 80px 100px;
    };
    @media (min-width: 800px) {
      $pad: calc((100% - 760px) / 2);
      padding-left: $pad;
      padding-right: $pad;
    }
    padding-bottom: 80px;
    overflow: scroll;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__cta {
    position: absolute;
    bottom: 20px;
  }
}

.user-info {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}