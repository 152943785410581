.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  &__dot {
    $radius: 8px;
    cursor: pointer;

    width: $radius;
    height: $radius;
    background-color: white;
    opacity: 0.5;
    border-radius: $radius;
    margin: 0 $radius / 2;
    transition: 300ms;

    &_active {
      opacity: 0.8;
    }
  }
}