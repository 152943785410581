.signup {
  &__instructions {
    margin-top: 20px;
  }

  &__error {
    padding: 10px 20px;
    background-color: #ff6868;
    border-radius: 6px;
  }

  &__cta {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    div {
      width: 100%;
      max-width: 300px;
    }
  }
}

.signup-step {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: 10px 0;
  &__num {
    $size: 40px;
    height: $size;
    width: $size;
    border: solid 1px white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    border-radius: 1000px;
    font-size: 1.2em;
    span {
      margin-top: -0.1em;
    }
  }
  &__content {
    flex: 1;
    margin-left: 10px;
  }

}