.menu {
  z-index: 1000001;
  &__icon {
    $color: rgba(255,255,255,1);
    width: 28px;
    height: 20px;
    left: 20px;
    top: 33px;
    position: absolute;
    background-color: white;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    background: linear-gradient(
      0deg,
      $color 0%, $color 15%,
      transparent 15%, transparent 42.5%,
      $color 42.5%, $color 57.5%,
      transparent 57.5%, transparent 85%,
      $color 85%, $color 100%
    );
  }

  &__cross {
    position: absolute;
    right: 10px;
    top: 20px;
    padding: 6px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    img {
      width: 20px;
      height: 20px !important;
    }
  }

  &__content {
    // display: none;
    padding: 70px 20px 20px 20px;
    box-sizing: border-box;
    position: absolute;
    background-color: #2a003c;
    background: linear-gradient(155deg, #2a003c, #15001f);
    top: 0;
    left: 0;
    margin-left: -100%;
    height: 100vh;
    width: 75%;
    max-width: 400px;
    transition: 0.5s;

    &_open {
      margin-left: 0px;
    }
  }

  &__item {
    padding: 20px 0;
    border-bottom: solid 1px rgba(255, 255, 255, 0.25);
    text-decoration: none !important;
    display: block;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  &__paranga {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    transition: opacity 0.5s;
    opacity: 0;

    &_open {
      opacity: 1;
      display: inherit;
    }
  }
}