.personal {
  &__promocode {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    padding: 10px 0;

    &-value {
      flex: 1;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title {
        font-size: 2em;
      }
      &-caption {
        font-size: 0.75em;
      }
    }

    &-share {
      flex: 1;
      padding-left: 10px;
    }
  }
}