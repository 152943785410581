// .presents {
// }

.present-item {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  &__preview {
    width: 33%;
    padding: 5px 5px 5px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &__body {
    width: 67%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 1.2em;
    font-weight: 600;
  }

  &__description {
    margin-top: 0.5em;
  }
}