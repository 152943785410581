@import '../../graident.scss';

.button {
    background-color: white;
    padding: 12px 20px;
    border-radius: 6000px;
    user-select: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    transition: 300ms;

    &:hover {
        box-shadow: 0 2px 6px rgba(0,0,0,0.5);
    }

    &__content {
        @extend %gradient;
        color:transparent;
        -webkit-background-clip: text;
        background-clip: text;
        // color: #333399;
    }

    &_size {
        &_large {
            padding: 14px 22px;
            font-size: 1.1em;
        }
    }
}
