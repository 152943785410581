$height: 50px;

.rating-item {
  color: #333;
  padding: 0 8px 0 0;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  font-size: 0.8em;
  align-items: center;
  height: $height + 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  &__avatar {
    width: $height;
    height: $height;
    padding: 10px;
    box-sizing: border-box;
    border-radius: $height;

    &_0 {
      img {
        box-shadow: 0 0 0px 2px #FFDF00;
      }
    }

    &_1 {
      img {
        box-shadow: 0 0 0px 2px #C0C0C0;
      }
    }

    &_2 {
      img {
        box-shadow: 0 0 0px 2px #cd7f32;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 1000px;
      border: solid 1px white;
    }
  }

  &__body {
    flex: 1;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-name {
      font-weight: 500;
    }

    &-city {
      font-size: 0.9em;
      color: #777;
      margin-top: 4px;
    }
  }

  &__score {
    width: 55px;
    text-align: right;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-value {
      font-size: 2em;
    }
    &-caption {
      font-size: 0.75em;
    }
  }
}