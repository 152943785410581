.pre-loader {
  display: flex;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}